import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { App } from './App';
import { extendTheme } from '@chakra-ui/react';
import 'focus-visible/dist/focus-visible';
// import { AuthProvider } from './firebase/AuthContext';

export type BoatColors = {
  navyblue: string;
  sapphireblue: string;
  stoneblue: string;
  lightbeige: string;
  sahara: string;
};

export const boatColors: BoatColors = {
  navyblue: '#0A1D2B',
  sapphireblue: '#0F475D',
  stoneblue: '#607B84',
  lightbeige: '#F9EDDB',
  sahara: '#E79538',
};

const theme = extendTheme({
  styles: {
    global: {
      p: {
        color: 'black',
      },
    },
  },
  fonts: {
    heading: 'monospace',
    body: 'monospace',
  },
  colors: {
    boat: boatColors,
  },
  components: {
    Table: {
      variants: {
        simple: {
          th: {
            paddingLeft: 0,
          },
          td: {
            paddingLeft: 0,
          },
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 0,
        textTransform: 'uppercase',
      },
      variants: {
        main: {
          color: '#ED7029',
        },
      },
    },
    Menu: {
      parts: ['list'],
      baseStyle: {
        list: {
          borderRadius: 0,
          padding: 0,
        },
      },
    },
    Modal: {
      parts: ['dialog'],
      baseStyle: {
        dialog: {
          borderRadius: 0,
        },
      },
    },
    Input: {
      sizes: {
        md: {
          field: {
            borderRadius: 0,
          },
          addon: {
            borderRadius: 0,
          },
        },
      },
    },
  },
});

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      {/*<AuthProvider>*/}
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
      {/*</AuthProvider>*/}
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
