import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import { useAuthState } from './firebase/AuthContext';
// import { Signin } from './Signin';
import { useGetLatestStat } from './hooks/stat';
import { LatestStat } from './features/stat/LatestStat';
import { Chart } from './features/chart/Chart';
import { boatColors } from '.';

export const App: React.FC = () => {
  // const auth = useAuthState();

  // if (auth.isLoading) return <Box h='100vh' w='100vw'></Box>;

  // if (!auth.isLoading && !auth.user) return <Signin />;

  return (
    <Box h='100%' w='100%' minH='100vh' bgColor={boatColors.lightbeige}>
      <Router>
        <Layout />
      </Router>
    </Box>
  );
};

const Layout: React.FC = () => {
  const { data: latestStat, isLoading } = useGetLatestStat();

  if (isLoading) return <></>;

  //if (!isLoading && error?.status === 404)
  //  return <CreateDirector refetchDirector={refetch} />;

  return (
    <Box>
      <Box>{latestStat && <LatestStat stat={latestStat} />}</Box>
      <Flex justifyContent='center'>
        <Chart />
      </Flex>
    </Box>
  );
};
