import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { boatColors } from '../..';
import { StatView } from '../../ApiClients';

interface Props {
  stat: StatView | undefined;
}

export const StatDetail: React.FC<Props> = ({ stat }) => {
  if (!stat) return <></>;

  return (
    <Box>
      <Row title='Total boats' value={stat.totalBoats} />
      <Row title='Avg. Year' value={stat.avgYear} />
      <Row title='Mean Year' value={stat.meanYear} />
      <Row title='Min Year' value={stat.minYear} />
      <Row title='Max Year' value={stat.maxYear} />
      <Row title='Avg. Feet' value={stat.avgFeet} />
      <Row title='Mean Feet' value={stat.meanFeet} />
      <Row title='Min Feet' value={stat.minFeet} />
      <Row title='Max Feet' value={stat.maxFeet} />
      <Row title='Total Feet' value={stat.totalFeet.toLocaleString('no')} />
      <Row title='Avg. Price' value={stat.avgPrice.toLocaleString('no')} />
      <Row title='Mean Price' value={stat.meanPrice.toLocaleString('no')} />
      <Row title='Min Price' value={stat.minPrice.toLocaleString('no')} />
      <Row title='Max Price' value={stat.maxPrice.toLocaleString('no')} />
      <Row title='Total Price' value={stat.totalPrice.toLocaleString('no')} />
      <Row
        title='Created'
        value={new Date(stat.createdUtc)
          .toLocaleDateString()
          .replaceAll('/', '.')}
      />
    </Box>
  );
};

const Row: React.FC<{ title: any; value: any }> = ({ title, value }) => {
  return (
    <Flex justifyContent='space-between' mb={1}>
      <Text mr={2} color={boatColors.navyblue}>
        {title}:
      </Text>
      <Text color={boatColors.navyblue}>{value}</Text>
    </Flex>
  );
};
