import { useQuery } from 'react-query';
import {
  LatestStatView,
  StatClient,
  ChartData,
  ChartDataMetricType,
  ChartDataRangeType,
} from '../ApiClients';

const statClient = new StatClient();

export const useGetLatestStat = () =>
  useQuery<LatestStatView, any>(`stat-latest`, () => statClient.getLatest(), {
    retry: (failureCount, error: any) => {
      return error.status !== 404;
    },
  });

export const useGetChartData = (
  rangeType: ChartDataRangeType,
  metricType: ChartDataMetricType
) =>
  useQuery<ChartData, any>(
    `stats-chart-${rangeType}-${metricType}`,
    () => statClient.getChart(rangeType, metricType),
    {
      retry: (failureCount, error: any) => {
        return error.status !== 404;
      },
    }
  );
