import { Box, Flex, Select } from '@chakra-ui/react';
import React, { useState } from 'react';
import { boatColors } from '../..';
import { ChartDataMetricType, ChartDataRangeType } from '../../ApiClients';
import { BLineChart } from '../../components/BLineChart';
import { useGetChartData } from '../../hooks/stat';

export const Chart: React.FC = () => {
  const [selectedRange, setSelectedRange] = useState('OneWeek');
  const [selectedMetric, setSelectedMetric] = useState('Index');

  const { data: chartData } = useGetChartData(
    ChartDataRangeType[selectedRange as keyof typeof ChartDataRangeType],
    ChartDataMetricType[selectedRange as keyof typeof ChartDataMetricType]
  );

  const data = {
    labels: chartData && chartData.labels ? chartData.labels : [],
    datasets: [
      {
        label: '',
        data: chartData && chartData.values ? chartData.values : [],
        borderColor: 'white',
        fill: true,
        backgroundColor: 'rgb(15, 71, 93, 0.7)',
        borderWidth: 0.6,
      },
    ],
  };

  const chartDataRangeTypes = Object.values(ChartDataRangeType).filter(
    (x) => typeof x === 'string'
  );

  const chartDataMetricType = Object.values(ChartDataMetricType).filter(
    (x) => typeof x === 'string'
  );

  return (
    <Box p={8} maxW='1500px'>
      <Flex justifyContent='space-between'>
        <Flex>
          <Select
            placeholder='Select metric'
            maxW='200px'
            value={selectedMetric}
            onChange={(e) => setSelectedMetric(e.target.value)}
            borderColor={boatColors.navyblue}
            color={boatColors.navyblue}
            _hover={{ borderColor: boatColors.navyblue }}
          >
            {chartDataMetricType.map((c, i) => (
              <option key={i} value={c}>
                {c}
              </option>
            ))}
          </Select>
          <Box w={2} />
          <Select
            placeholder='Select range'
            maxW='200px'
            value={selectedRange}
            onChange={(e) => setSelectedRange(e.target.value)}
            borderColor={boatColors.navyblue}
            color={boatColors.navyblue}
            _hover={{ borderColor: boatColors.navyblue }}
          >
            {chartDataRangeTypes.map((c, i) => (
              <option key={i} value={c}>
                {c}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex
          justifyContent='center'
          alignContent='center'
          flexDir='column'
          fontSize={26}
          color={boatColors.navyblue}
        >
          {chartData && `${chartData?.changePercent}%`}
        </Flex>
      </Flex>
      <BLineChart data={data} />
    </Box>
  );
};
