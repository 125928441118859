import { Box, Collapse, Flex, IconButton, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { boatColors } from '../..';
import { LatestStatView } from '../../ApiClients';
import { StatDetail } from './StatDetail';
import { GiCompass } from 'react-icons/gi';

interface Props {
  stat: LatestStatView;
}

export const LatestStat: React.FC<Props> = ({ stat }) => {
  const [detailIsOpen, setDetailIsOpen] = useState(false);
  return (
    <Flex justifyContent='center' pt={8}>
      <Box>
        <Box
          bgColor={boatColors.stoneblue}
          p={2}
          w='230px'
          mb={4}
          shadow='lg'
          borderRadius='md'
        >
          <Text fontWeight='bold' color={boatColors.lightbeige} fontSize={16}>
            Latest price per feet
          </Text>
          <Text color={boatColors.lightbeige}>
            {stat.statView &&
              new Date(stat.statView?.createdUtc)
                .toLocaleDateString()
                .replaceAll('/', '.')}
          </Text>
          <Flex>
            <Text fontWeight='bold' fontSize={32} color={boatColors.sahara}>
              {stat.statView?.index.toLocaleString('no')}
            </Text>
            <Text
              fontSize={16}
              color={boatColors.lightbeige}
              w='100%'
              textAlign='center'
              pt='17px'
            >
              {`${stat.oneDayChange}%`}
            </Text>
          </Flex>
        </Box>
        <Flex justifyContent='center' mb={4}>
          <IconButton
            aria-label='Toggle details'
            icon={<GiCompass />}
            colorScheme='ghost'
            variant='solid'
            color={boatColors.navyblue}
            fontSize='40px'
            _hover={{
              backgroundColor: boatColors.lightbeige,
            }}
            onClick={() => setDetailIsOpen((prev) => !prev)}
          />
        </Flex>
        <Collapse in={detailIsOpen} animateOpacity>
          <StatDetail stat={stat.statView} />
        </Collapse>
      </Box>
    </Flex>
  );
};
